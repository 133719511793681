import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ImportStep } from '@/types/enum';
import { API_CLIENT, container } from '@/container';
import { ApiClient } from '@/services/api-client';
import { NotificationMessage } from '@/services/notifications';

interface ImportStateImportStepInitial {
  importFile: null;
  step: ImportStep.SETTINGS;
}

interface ImportStateImportStepLaunched {
  importFile: App.Import;
  step: ImportStep.MAPPING | ImportStep.ERROR_REPORT | ImportStep.IMPORT;
}

type ImportState = ImportStateImportStepInitial | ImportStateImportStepLaunched;

const importState: ImportState = {
  importFile: null,
  step: ImportStep.SETTINGS,
};

const mutations: MutationTree<ImportState> = {
  START: (state, { importFile, step }: { importFile: App.Import; step: ImportStep }) => {
    state.importFile = importFile;
    state.step = step;
  },
  CLEAN: state => {
    state.importFile = null;
    state.step = ImportStep.SETTINGS;
  },
  CHANGE_STEP: (state, step: ImportStep) => {
    state.step = step;
  },
  UPDATE_MAPPINGS: (state, mappings: Array<App.Mapping>) => {
    if (state.importFile) {
      state.importFile.mappings = mappings;
    }
  },
  UPDATE_FILE_ROW_ERRORS: (state, fileRowErrors: Array<App.FileRowError>) => {
    if (state.importFile) {
      state.importFile.fileRows = fileRowErrors;
    }
  },
};

const actions: ActionTree<ImportState, ImportState> = {
  async init({ commit }, startOptions: { id: number; step: ImportStep }) {
    try {
      const { data } = await container.get<ApiClient>(API_CLIENT).get(`/api/imports/${startOptions.id}`);

      commit('START', { importFile: data, step: startOptions.step });
    } catch (e) {
      NotificationMessage.error(e.message);
    }
  },
  async start({ commit }, startOptions: { importFile: App.Import; step: ImportStep }) {
    commit('START', startOptions);
  },
  async reset({ commit, dispatch }) {
    commit('CLEAN');
    dispatch('mapping/reset', null, { root: true });
  },
  async changeStep({ commit, state, dispatch }, step: ImportStep) {
    commit('CHANGE_STEP', step);

    if (ImportStep.MAPPING === state.step && null !== state.importFile.baseSiteId) {
      dispatch('mapping/initStore', state.importFile.baseSiteId, { root: true });
    }

    if (ImportStep.ERROR_REPORT === state.step && null !== state.importFile.id) {
      dispatch('dataValidation/initStore', state.importFile.id, { root: true });
    }
  },
  async updateMappings({ commit }, mappings: Array<App.Mapping>) {
    commit('UPDATE_MAPPINGS', mappings);
  },
  async updateFileRowErrors({ commit }, fileRows: Array<App.FileRowError>) {
    commit('UPDATE_FILE_ROW_ERRORS', fileRows);
  },
};

const getters: GetterTree<ImportState, any> = {
  currentImport: (state): App.Import | null => state.importFile,
  currentFilename: state => state.importFile?.filename,
  fileUploaded: state => null !== state.importFile,
};

export const ImportFileModule: Module<ImportState, any> = {
  namespaced: true,
  state: importState,
  mutations,
  actions,
  getters,
};
