import { Container } from 'inversify';
import { API_CLIENT, API_URL, SERVICE_IMPORTER, SERVICE_MAPPING_TYPES_PROPERTY } from '@/container/types';
import { ApiClient } from '@/services/api-client';
import Importer from '@/services/importer';
import MappingTypesProperty from '@/services/mapping-types-property';

const container = new Container();

container.bind<string>(API_URL).toConstantValue(process.env.VUE_APP_API_URL as string);
container.bind<ApiClient>(API_CLIENT).to(ApiClient);

container.bind<Importer>(SERVICE_IMPORTER).to(Importer);
container.bind<MappingTypesProperty>(SERVICE_MAPPING_TYPES_PROPERTY).to(MappingTypesProperty);

export { container };
