import uid from 'uid';
import { NotificationMessageTypes } from '@/types/enum';

export class NotificationMessage {
  private static DEFAULT_TIMEOUT = 5000;

  public readonly id: string;
  public readonly message: string;
  public readonly type: NotificationMessageTypes;
  public displayed: boolean;
  public timeout: number;

  private constructor(message: string, type: NotificationMessageTypes, timeout: number) {
    this.id = uid(10);
    this.message = message;
    this.type = type;
    this.timeout = timeout;
    this.displayed = true;
  }

  public hide(): void {
    this.displayed = false;
  }

  public static success(message: string, timeout: number = this.DEFAULT_TIMEOUT): NotificationMessage {
    return new NotificationMessage(message, NotificationMessageTypes.SUCCESS, timeout);
  }

  public static error(message: string, timeout: number = this.DEFAULT_TIMEOUT): NotificationMessage {
    return new NotificationMessage(message, NotificationMessageTypes.ERROR, timeout);
  }

  public static info(message: string, timeout: number = this.DEFAULT_TIMEOUT): NotificationMessage {
    return new NotificationMessage(message, NotificationMessageTypes.INFO, timeout);
  }
}
