import { localize, extend } from 'vee-validate';
import { email, max, min, mimes, required, size } from 'vee-validate/dist/rules';

localize('fr', require('vee-validate/dist/locale/fr'));

extend('email', email);
extend('max', max);
extend('min', min);
extend('mimes', mimes);
extend('required', required);
extend('size', size);

export const localizeValidator = localize;
export const extendValidator = extend;
