




















import { Component, Vue, Watch } from 'vue-property-decorator';
import { NotificationMessage } from '@/services/notifications';

@Component
export default class TheNotificationHandler extends Vue {
  private notification: NotificationMessage | null = null;
  private unsubscribe: Function | null = null;

  mounted(): void {
    this.unsubscribe = this.$store.subscribeAction({ after: this.handleStoreAction });
  }

  beforeDestroy(): void {
    if (null !== this.unsubscribe) {
      this.unsubscribe();
    }
  }

  @Watch('notification', { deep: true })
  notificationWatcher(notification: NotificationMessage | null): void {
    if (null !== notification && !notification.displayed) {
      this.pullNotification();
    }
  }

  async handleStoreAction({ type }: { type: string }): Promise<void> {
    if (type === 'notification/dispatch' && null === this.notification) {
      await this.pullNotification();
    }
  }

  async pullNotification(): Promise<void> {
    this.notification = await this.$store.dispatch('notification/pop');
  }
}
