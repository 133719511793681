import Vue, { VNode } from 'vue';
import App from './App.vue';
import './plugins/container';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { i18n } from './plugins/vue-i18n';
import './plugins/vee-validate';
import './plugins/vue-meta';
import './plugins/sentry';
import './main.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h): VNode => h(App),
}).$mount('#app');
