import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations/dist/vue';

if ('prod' === process.env.VUE_APP_ENV || 'staging' === process.env.VUE_APP_ENV) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  });
}
