












import { Component, Vue } from 'vue-property-decorator';
import TheHeader from '@/components/TheHeader.vue';
import TheNotificationHandler from '@/components/TheNotificationHandler.vue';

@Component({
  components: { TheHeader, TheNotificationHandler },
})
export default class App extends Vue {}
