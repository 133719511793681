import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { NotificationMessage } from '@/services/notifications';

export interface NotificationState {
  messages: Array<NotificationMessage>;
}

const authState: NotificationState = {
  messages: [],
};

const mutations: MutationTree<NotificationState> = {
  PUSH: (state, message) => {
    state.messages.push(message);
  },
  POP: state => {
    state.messages.shift();
  },
};

const actions: ActionTree<NotificationState, NotificationState> = {
  dispatch({ commit, state }, message: NotificationMessage): void {
    commit('PUSH', message);
  },
  pop({ commit, state }): NotificationMessage | null {
    if (state.messages.length <= 0) {
      return null;
    }

    const poppedMessage = state.messages[0];
    commit('POP');

    return poppedMessage;
  },
};

const getters: GetterTree<NotificationState, any> = {
  getMessages: (state): Array<NotificationMessage> => state.messages,
};

export const NotificationModule: Module<NotificationState, any> = {
  namespaced: true,
  state: authState,
  mutations,
  actions,
  getters,
};
