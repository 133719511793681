import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ImportStep } from '@/types/enum';
import { container, SERVICE_IMPORTER } from '@/container';
import Importer from '@/services/importer';
import { makeRequestBag, RequestBag } from '@/utils/request-bag';
import { NotificationMessage } from '@/services/notifications';

interface MappingState {
  initialized: boolean;
  platformId: number;
  mappers: Map<string, App.Mapper>;
  platform: {
    customFields: Array<App.CustomField>;
    articles: Array<App.Article>;
    categories: Array<App.Category>;
  };
  requestBag: RequestBag<any>;
}

const mappingState: MappingState = {
  initialized: false,
  platformId: -1,
  mappers: new Map(),
  platform: {
    customFields: [],
    articles: [],
    categories: [],
  },
  requestBag: makeRequestBag({ data: [] }),
};

const mutations: MutationTree<MappingState> = {
  INIT: (
    state,
    {
      platformId,
      mappers,
      customFields,
      articles,
      categories,
    }: {
      platformId: number;
      mappers: Map<string, App.Mapper>;
      customFields: Array<App.CustomField>;
      articles: Array<App.Article>;
      categories: Array<App.Category>;
    }
  ) => {
    state.platformId = platformId;
    state.initialized = true;
    state.mappers = mappers;
    state.platform.customFields = customFields;
    state.platform.articles = articles;
    state.platform.categories = categories;
  },
  RESET: state => {
    state.initialized = false;
  },
  LOADING: (state, loading) => {
    state.requestBag.loading = loading;
  },
};

const actions: ActionTree<MappingState, MappingState> = {
  async initStore({ commit, state, dispatch }, platformId) {
    if (state.initialized && state.platformId === platformId) {
      return;
    }

    const importService = container.get<Importer>(SERVICE_IMPORTER);

    try {
      commit('LOADING', true);
      const [mappers, customFields, articles, categories] = await Promise.all([
        importService.fetchMappers(),
        importService.fetchPlatformCustomFields(platformId),
        importService.fetchPlatformArticles(platformId),
        importService.fetchPlatformCategories(platformId),
      ]);

      commit('INIT', { platformId, mappers, customFields, articles, categories });
    } catch (e) {
      dispatch('notification/dispatch', NotificationMessage.error(e.message), { root: true });
      dispatch('importFile/changeStep', ImportStep.SETTINGS, { root: true });
    } finally {
      commit('LOADING', false);
    }
  },
  async reset({ commit }) {
    commit('RESET');
  },
};

const getters: GetterTree<MappingState, any> = {
  requestBag: state => state.requestBag,
  getMapper: state => (mapperType: string): App.Mapper | null =>
    state.initialized ? state.mappers.get(mapperType) || null : null,
};

export const MappingModule: Module<MappingState, any> = {
  namespaced: true,
  state: mappingState,
  mutations,
  actions,
  getters,
};
