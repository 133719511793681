import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/src/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#2F157F',
        secondary: '#4527A0',
        tertiary: '#311B90',
        backgroundApp: '#F1F6F8',
        white: '#FFF',
        black: '#000',
        primaryGradientStart: '#0C066F',
        primaryGradientStop: '#6F2A94',
        oddArray: '#FAF8FC',
        lightPurple: '#EDE7F6',
      },
    },
  },
  iconfont: 'fa',
});
