import Vue from 'vue';
import Vuex from 'vuex';
import { AuthModule } from './modules/auth';
import { NotificationModule } from './modules/notification';
import { ImportFileModule } from '@/store/modules/import-file';
import { MappingModule } from '@/store/modules/mapping';
import { DataValidationModule } from '@/store/modules/data-validation';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    notification: NotificationModule,
    importFile: ImportFileModule,
    mapping: MappingModule,
    dataValidation: DataValidationModule,
  },
});
