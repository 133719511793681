import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ImportStep } from '@/types/enum';
import { container, SERVICE_IMPORTER } from '@/container';
import Importer from '@/services/importer';
import { makeRequestBag, RequestBag } from '@/utils/request-bag';
import { NotificationMessage } from '@/services/notifications';

interface DataValidationState {
  initialized: boolean;
  requestBag: RequestBag<any>;
}

const dataValidationState: DataValidationState = {
  initialized: false,
  requestBag: makeRequestBag({ data: [] }),
};

const mutations: MutationTree<DataValidationState> = {
  INIT: state => {
    state.initialized = true;
  },
  RESET: state => {
    state.initialized = false;
  },
  LOADING: (state, loading) => {
    state.requestBag.loading = loading;
  },
};

const actions: ActionTree<DataValidationState, DataValidationState> = {
  async initStore({ commit, state, dispatch }, importId) {
    const importService = container.get<Importer>(SERVICE_IMPORTER);

    try {
      commit('LOADING', true);
      const validationReport = await importService.fetchImportErrors(importId);

      dispatch('importFile/updateFileRowErrors', validationReport, { root: true });
      commit('INIT');
    } catch (e) {
      dispatch('notification/dispatch', NotificationMessage.error(e.message), { root: true });
      dispatch('importFile/changeStep', ImportStep.MAPPING, { root: true });
    } finally {
      commit('LOADING', false);
    }
  },
  async reset({ commit }) {
    commit('RESET');
  },
};

const getters: GetterTree<DataValidationState, any> = {
  requestBag: state => state.requestBag,
};

export const DataValidationModule: Module<DataValidationState, any> = {
  namespaced: true,
  state: dataValidationState,
  mutations,
  actions,
  getters,
};
