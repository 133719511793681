import { container, API_CLIENT } from '@/container';
import { ApiClient } from '@/services/api-client';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import User = App.User;

type AuthState = {
  initialized: boolean;
} & ({ authenticated: false; user: null } | { authenticated: true; user: User });

const authState: AuthState = {
  initialized: false,
  authenticated: false,
  user: null,
};

const mutations: MutationTree<AuthState> = {
  INIT: (state, { user, authenticated }) => {
    state.authenticated = authenticated;
    state.user = user;
    state.initialized = true;
  },
  LOGOUT: state => {
    state.authenticated = false;
    state.user = null;
  },
};

// TODO: login + http cookie storage + init logged : false
const actions: ActionTree<AuthState, AuthState> = {
  async initStore({ commit, state }) {
    if (!state.initialized) {
      const { data } = await container
        .get<ApiClient>(API_CLIENT)
        .get<{ authenticated: boolean; user: User | null }>('/me');

      commit('INIT', { authenticated: data.authenticated, user: data.user });
    }
  },
  async logout({ commit }) {
    await container.get<ApiClient>(API_CLIENT).post('/logout');

    commit('LOGOUT');
  },
};

const getters: GetterTree<AuthState, any> = {
  getUser: state => state.user,
  isUserAuthenticated: state => state.authenticated,
};

export const AuthModule: Module<AuthState, any> = {
  namespaced: true,
  state: authState,
  mutations,
  actions,
  getters,
};
