import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import { ImportStep } from '@/types/enum';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'), // eslint-disable-line @typescript-eslint/explicit-function-return-type
      meta: {
        requireAuth: true,
      },
    },
    {
      path: '/import/:id',
      name: 'import',
      component: () => import(/* webpackChunkName: "home" */ '../views/Import.vue'), // eslint-disable-line @typescript-eslint/explicit-function-return-type
      meta: {
        requireAuth: true,
      },
      beforeEnter: async (to, from, next): Promise<void> => {
        const currentImport = store.getters['importFile/currentImport'];

        if (!currentImport || currentImport.id.toString() !== to.params.id) {
          await store.dispatch('importFile/init', { id: to.params.id, step: ImportStep.SETTINGS });
        }

        if (!store.getters['importFile/currentImport']) {
          next({ name: 'home' });
        }

        next();
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'), // eslint-disable-line @typescript-eslint/explicit-function-return-type
      meta: {
        requireAuth: false,
      },
      beforeEnter(to, from, next): void {
        if (store.getters['auth/isUserAuthenticated']) {
          next({ name: 'home' });
        } else {
          next();
        }
      },
    },
    {
      name: 'logout',
      path: '/logout',
      beforeEnter: async (to, from, next): Promise<void> => {
        await store.dispatch('auth/logout');
        next({
          name: 'home',
          query: {
            loggedOut: undefined, // "force" vue-router to not re-render the same PageHome instance when logged-out
          },
        });
      },
    },
    {
      path: '*',
      redirect: { name: 'home' },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/initStore');

  if (typeof to.query.redirect === 'string' && store.getters['auth/isUserAuthenticated']) {
    next(to.query.redirect);
    return;
  }

  if (to.matched.some(record => record.meta.requireAuth) && !store.getters['auth/isUserAuthenticated']) {
    next({
      name: 'login',
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    next();
  }
});

export default router;
