import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { messages } from '@/translations/messages';

Vue.use(VueI18n);

const options: VueI18n.I18nOptions = {
  locale: 'fr',
  messages: {},
  silentTranslationWarn: true,
};

if ('e2e' !== process.env.VUE_APP_ENV) {
  options.messages = messages;
}

export const i18n = new VueI18n(options);
