enum ImportStep {
  SETTINGS = 0,
  MAPPING = 1,
  ERROR_REPORT = 2,
  IMPORT = 3,
}

namespace ImportStep {
  // eslint-disable-next-line no-inner-declarations
  export function next(step: ImportStep): ImportStep {
    if (step === ImportStep.IMPORT) {
      return step;
    }

    return step + 1;
  }

  // eslint-disable-next-line no-inner-declarations
  export function previous(step: ImportStep): ImportStep {
    if (step === ImportStep.SETTINGS) {
      return step;
    }

    return step - 1;
  }
}

export { ImportStep };

export enum NotificationMessageTypes {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export enum ValidationErrorCategory {
  ERROR = 'ERROR',
  EMPTY = 'EMPTY',
  WARNING = 'WARNING',
}

export enum MapperTypes {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',
  CATEGORIES = 'categories',
  PUBLISH_SUBCATEGORIES = 'publish_subcategories',
  PUBLISH_ARTICLES_GLOBAL = 'publish_articles_global',
  UNPUBLISH_ARTICLES = 'unpublish_articles',
  UNPUBLISH_ARTICLES_CATEGORY = 'unpublish_articles_category',
  WANNA_SPEAK_DID_PHONE = 'wanna_speak_did_phone',
  WANNA_SPEAK_SMS_MISSED_CALL = 'wanna_speak_sms_missed_call',
  WANNA_SPEAK_SMS_SENDER_NAME = 'wanna_speak_sms_sender_name',
  WANNA_SPEAK_SMS_COMPANY_NAME = 'wanna_speak_sms_company_name',
  PASSWORD = 'password',
  ENTERPRISE = 'enterprise',
  ADDRESS = 'address',
  TOWN = 'town',
  POSTAL_CODE = 'postal_code',
  TEL = 'tel',
  OPENING_HOURS = 'opening_hours',
  LOCALE = 'locale',
  HOST = 'host',
  CUSTOM_FIELD = 'custom_field',
  IGNORED = 'ignored',
  PUBLISH_ARTICLES = 'publish_articles',
  SITE_IDENTIFIER = 'site_identifier',
  SITE_ID = 'site_id',
  SITE_WWW = 'site_www',
  ZOHO_PLAN = 'zoho_plan',
  ZOHOPRICE = 'zohoprice',
  ZOHOPRESCRIBER = 'zohoprescriber',
  ZOHOTYPE = 'zohotype',
  ZOHOSECTOR = 'zohosector',
  ZOHOINTEGRATOR = 'zohointegrator',
  TAGS = 'tags',
  WANNA_SPEAK_CALLERID = 'wanna_speak_callerid',
}

export enum FileRowImportStatus {
  NEW = 0,
  POST = 10,
  REPOST = 11,
  SUCCESS = 20,
  ERROR = 30,
}

export enum ImportStatus {
  NOT_IMPORTED = 'NOT_IMPORTED',
  PARTIALLY_IMPORTED = 'PARTIALLY_IMPORTED',
  IMPORTED = 'IMPORTED',
}

export enum PublishApiErrorTypes {
  WARNING = 'WARNING',
  ENDPOINT = 'ENDPOINT',
  OTHER = 'OTHER',
}
