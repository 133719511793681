import { inject, injectable } from 'inversify';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import QueryString from 'qs';
import { API_URL } from '@/container';

@injectable()
export class ApiClient {
  private client: AxiosInstance;

  constructor(@inject(API_URL) private baseURL: string) {
    this.client = axios.create({
      baseURL,
      withCredentials: true,
      paramsSerializer: function(params) {
        return QueryString.stringify(params, { arrayFormat: 'brackets' });
      },
    });
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.get(url, config);
  }
  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.delete(url, config);
  }
  head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.head(url, config);
  }
  options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.client.options(url, config);
  }
  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.client.post(url, data, config);
  }
  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.client.put(url, data, config);
  }
  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.client.patch(url, data, config);
  }
}
