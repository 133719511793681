import { MapperTypes } from '@/types/enum';
import { injectable } from 'inversify';
import store from '@/store';

export interface ExpectedValueMapItem {
  getter: string;
  value: string;
  text: string;
}

@injectable()
export default class MappingTypesProperty {
  private expectedValueMap: Map<MapperTypes, ExpectedValueMapItem> = new Map([
    [
      MapperTypes.CUSTOM_FIELD,
      {
        getter: 'customFields',
        text: 'token',
        value: 'id',
      },
    ],
    [
      MapperTypes.PUBLISH_SUBCATEGORIES,
      {
        getter: 'categories',
        text: 'translations[0].title',
        value: 'id',
      },
    ],
    [
      MapperTypes.UNPUBLISH_ARTICLES_CATEGORY,
      {
        getter: 'categories',
        text: 'translations[0].title',
        value: 'id',
      },
    ],
    [
      MapperTypes.PUBLISH_ARTICLES,
      {
        getter: 'articles',
        text: 'translations[0].title',
        value: 'id',
      },
    ],
  ]);

  public getTypeValue(mapperType: MapperTypes): ExpectedValueMapItem | undefined {
    return this.expectedValueMap.get(mapperType);
  }

  public canConfigureTypeValue(mapperType: MapperTypes): boolean {
    return this.expectedValueMap.has(mapperType);
  }

  public canConfigureNullable(mapperType: MapperTypes): boolean {
    const mapper: App.Mapper | null = store.getters['mapping/getMapper'](mapperType);

    if (null === mapper) {
      return false;
    }

    return mapper.optionNullable;
  }
}
