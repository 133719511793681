import { inject, injectable } from 'inversify';
import { ApiClient } from '@/services/api-client';
import { API_CLIENT, container } from '@/container';

@injectable()
export default class Importer {
  private client: ApiClient;

  constructor(@inject(API_CLIENT) apiclient: ApiClient) {
    this.client = apiclient;
  }

  public async fetchMappers(): Promise<Map<string, App.Mapper>> {
    const { data } = await container.get<ApiClient>(API_CLIENT).get('/mapping/fields');
    return new Map(Object.entries(data.fields));
  }

  public async fetchPlatformCustomFields(platformId: number): Promise<Array<App.CustomField>> {
    const { data } = await this.client.get<{ fields: Array<App.CustomField> }>(
      `/graphql/platforms/${platformId}/custom-fields`
    );

    return data.fields;
  }

  public async fetchPlatformArticles(platformId: number): Promise<Array<App.Article>> {
    const { data } = await this.client.get<{ articles: Array<App.Article> }>(
      `/graphql/platforms/${platformId}/articles`
    );

    return data.articles;
  }

  public async fetchPlatformCategories(platformId: number): Promise<Array<App.Category>> {
    const { data } = await this.client.get<{ categories: Array<App.Category> }>(
      `/graphql/platforms/${platformId}/categories`
    );

    return data.categories;
  }

  public async fetchImportErrors(importId: number): Promise<Array<App.FileRowError>> {
    const { data } = await this.client.get<ApiPlatform.Response<App.FileRowError>>(
      `/api/imports/${importId}/file_rows`,
      { params: { errors: true } }
    );

    return data['hydra:member'] ?? [];
  }
}
