



















































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TheHeader extends Vue {
  get isUserAuthenticated(): boolean {
    return this.$store.getters['auth/isUserAuthenticated'];
  }

  get user(): App.User {
    return this.$store.getters['auth/getUser'];
  }
}
