export const messages = {
  fr: {
    applicationName: 'Importer Y-Proximité',
    apiRequest: {
      error: "Une erreur s'est produite lors du chargement de la requête.",
    },
    action: {
      import: 'Importer',
      download: 'Télécharger',
      edit: 'Éditer',
      update: 'Modifier',
      cancel: 'Annuler',
      delete: 'Supprimer',
      close: 'Fermer',
      logout: 'Déconnexion',
      returnHome: "Retourner à l'accueil",
      nextStep: 'Étape suivante',
      confirmAndNextStep: "Confirmer et passer à l'étape suivante",
      updateMapping: 'Modifier le mapping',
      fixFileRows: 'Corriger les données',
    },
    form: {
      isNotValid: 'Le formulaire contient des erreurs.',
    },
    pagination: {
      loading: 'Chargement...',
    },
    label: {
      yes: 'Oui',
      no: 'Non',
      partially: 'Partiellement',
      unknown: 'Inconnu',
    },
    mapping: {
      id: 'Identifiant',
      firstName: 'Nom',
      lastName: 'Prénom',
      email: 'Email',
      address: 'Adresse',
      city: 'Ville',
      zipCode: 'Code postale',
      phone: 'Téléphone',
      openingHours: "Horaires d'ouverture",
      zohoSector: 'Métier',
      zohoIntegrator: 'Intégrateur',
      zohoPrescriber: 'Prescripteur',
      zohoType: 'Type de projet',
      zohoPrice: 'Prix - Zoho',
    },
    step: {
      file: {
        title: 'Envoi du fichier',
      },
      settings: {
        title: 'Paramètres',
        confirmationModal: {
          title: 'Attention !',
          description: {
            create: {
              sentence1: 'Vous allez créer de nouveaux sites.',
              sentence2: 'Merci de vérifier que la colonne suivante est bien présente dans votre fichier :',
              sentence2multiple:
                'Merci de vérifier que les colonnes suivantes sont bien présentes dans votre fichier :',
            },
            update: {
              sentence1: 'Vous allez mettre à jour des sites existants.',
              sentence2: 'Merci de vérifier que la colonne suivante est bien présente dans votre fichier :',
              sentence2multiple:
                'Merci de vérifier que les colonnes suivantes sont bien présentes dans votre fichier :',
            },
          },
        },
      },
      mapping: {
        title: 'Mapping',
        updateModal: {
          title: "Mapping d'une colonne",
          toggleNullableField: 'Le champ peut etre vide',
          toggleUseIdsField: 'Le champ contient des ids',
        },
        headers: {
          valid: '',
          column: 'Colonne',
          mappedBy: 'Mappé avec',
          example: 'Exemple de valeur',
          edit: 'Modifier',
          ignore: 'Ignorer',
        },
        noExemple: 'Aucun exemple disponible',
        noAssignedMapping: 'N/A',
        alertMappingUpdated: 'Le mapping a bien été enregistré',
        errorModal: {
          title: 'Erreurs détectées',
          description: 'Nous avons détecté des erreurs dans le mapping.',
          groups: {
            create: 'Création',
            edit: 'Edition',
            location: 'Localisation',
            callTracking: 'Numéro de suivi',
          },
          error: {
            uniques: 'Ces champs doivent être uniques :',
            groups: 'Ces champs sont manquants :',
            empties: 'Ces champs ne sont pas renseignés :',
          },
        },
      },
      dataValidation: {
        title: 'Données',
        toNextStep: 'Valider les données',
        notFoundProperty: 'Propriété non trouvé',
        alertFileRowUpdated: 'Ligne mise à jour',
        headers: {
          lineNumber: 'Numéro de ligne',
          lineValid: 'Ligne valide',
          actions: 'Modifier',
          siteId: 'Identifiant du site',
          company: 'Entreprise',
          name: 'Nom',
          siteName: 'Nom du site',
        },
        stats: {
          validLines: 'Lignes valides : {valid} / {total}',
          erroredLines: 'Lignes en anomalie : {anomaly} / {total}',
        },
        popover: {
          title: 'Anomalies détectées',
          errorTitle: 'Aucune erreur détectée | {count} erreur détectée | {count} erreurs détectées',
          warningTitle: 'Aucun avertissement détecté | {count} avertissement détecté | {count} avertissements détectés',
        },
        mappingType: {
          empty: 'Le champ {field} est vide',
          email: 'E-mail non valide',
          tel: 'Numéro de téléphone non valide',
          site_id: 'Identifiant non reconnu pour cette plateforme',
          zohotype: 'Type de projet non reconnu',
          zohosector: 'Métier non reconnu',
          zohointegrator: 'Intégrateur non reconnu',
        },
        updateFileRowModal: {
          title: 'Modifier une ligne',
        },
        warningModal: {
          title: 'Les données contiennent des erreurs/warnings.',
          description:
            "Vous pouvez continuer à l'étape suivante. Dans ce cas, les lignes en erreur ne pourront pas être importées, celles en warning le seront.",
        },
      },
      import: {
        title: 'Import',
        headers: {
          selectAll: 'Tout selectionner',
          siteId: 'Identifiant du site',
          company: 'Entreprise',
          lineNumber: 'Numéro de ligne',
          status: "Statut d'import",
          lineValid: 'Ligne valide',
          actions: 'Actions',
        },
        statusMessage: {
          new: 'Nouveau',
          post: 'Soumis',
          repost: 'Soumis',
          error: 'Erreur',
          success: 'Importé',
        },
        action: {
          reloadStatus: 'Recharger les statuts',
          importSelection: 'Importer la selection',
          showImportError: 'Voir les erreurs',
        },
        alerts: {
          noFileRowMatchCondition: "Aucune ligne ne remplit les conditions d'import",
          emptySelection: 'Aucune ligne sélectionnée',
          importLaunched: "L'import a bien été lancé",
          importFailed: "Une erreur est survenue lors du lancement de l'import",
          failedFileRowsReload: 'Une erreur est survenue lors du rechargement des statuts',
        },
        showErrorModal: {
          title: "Erreur lors de l'import d'une ligne",
          errorDuringImport: "Une erreur s'est produite lors de l'import de la ligne : {lineNumber}",
          lineNumber: 'Numéro de ligne : {lineNumber}',
          siteId: 'Identifiant du site : {siteId}',
          reportTab: 'Rapport {report}',
          apiParameters: 'Paramètres',
          apiRequest: 'Requête',
          apiResponse: 'Réponse',
        },
        confirmationForceModal: {
          description: {
            sentence1: 'Votre sélection contient des données déjà soumises.',
            sentence2: 'Vous pouvez tout envoyer ou alors uniquement ceux en statut "Nouveau".',
            sentence3:
              'Les sites en statut "Soumis", ne seront renvoyés que si cela fait plus de 10 minutes qu\'ils sont dans ce statut.',
          },
          radio: {
            all: 'Toutes les données sélectionnées',
            new: 'Seulement les données en statut "nouveau"',
          },
        },
      },
    },
    home: {
      title: 'Historique des fichiers envoyés',
      button: {
        downloadCreationTemplate: 'Template de création',
        downloadEditionTemplate: "Template d'édition",
        sendFile: 'Envoyer un fichier',
      },
      headers: {
        user: 'Utilisateur',
        filename: 'Nom du fichier',
        sentAt: "Date et heure d'envoi",
        rows: 'Nombre de lignes',
        imported: 'Importé',
        edit: 'Modifier',
      },
      deleteModal: {
        title: 'Êtes-vous sûr de vouloir supprimer cet import ?',
        description: 'Cette action est définitive et ne peut être annulée.',
        alertSuccess: "L'import a bien été supprimé.",
        importToDelete: "L'import à supprimer :",
      },
    },
  },
};
